import { createApp } from 'vue'
import HomePageApp from './HomePageApp.vue'
import {createRouter, createWebHashHistory} from "vue-router";
import HomePage from "@/components/HomePage.vue";


const routes = [
    { path: '/', component: HomePage, name: 'homepage' },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

const homePageApp = createApp(HomePageApp)
homePageApp.use(router)

homePageApp.mount('#app')